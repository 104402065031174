import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ProjectPreview from "../components/project_preview";
import { StaticImage } from "gatsby-plugin-image";

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { name: { eq: "main" } }) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            date
            heading
            description
            image
            title
            subheading
          }
        }
      }
    }
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(projects)/" } }) {
      nodes {
        frontmatter {
          image
          date
          description
          heading
          slug
          subheading
          title
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  let fm = data.allFile.nodes[0].childMarkdownRemark.frontmatter;
  let projects = data.allMarkdownRemark.nodes.map((n) => n.frontmatter);
  let pic = "../../static/logos/logo/logo.png";
  return (
    <Layout>
      <div className="relative w-full h-96 text-gray mb-12">
        <img
          src={fm.image}
          className="relative top-0 left-0 w-full h-full object-cover"
        />
        <div className="absolute top-0 left-0 w-full h-full flex justify-start items-center text-center flex-col">
          <h1 class="h1head">
            Timo Sarmiento Holzgestaltung
          </h1>
          <div class="imageDiv">
          <StaticImage
              // className="h-8 w-8 mx-2"
              class='imgASD'
              src="../../static/logos/logo/logo.png"
              alt={data.site.siteMetadata.title}
            />
            </div>
          {/* <h3 className="bg-beige m-3 h-5 text-1xl md:text-6xl mb-16 md:h-12"> */}
            {/* {fm.heading} */}
          {/* </h3> */}
          {/* <a
            href="/projects"
            className="bg-beige py-4 px-8 hover:bg-gray hover:text-beige"
          >
            {fm.subheading}
          </a> */}
        </div>
      </div>
      <div class="projects">
      {projects.map((n) => (
        <ProjectPreview content={n} />
      ))}
      </div>
    </Layout>
  );
};

export default IndexPage;
