import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = (data) => {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <header className="bg-brown z-10 relative px-6 py-8 flex justify-between items-center text-green border-b border-beige">
          <a
            href="/"
            className="bg-brown z-10 relative mx-2 flex justify-start items-center"
          >
            <StaticImage
              className="h-8 w-8 mx-2"
              src="../../static/logos/logo/logo.png"
              alt={data.site.siteMetadata.title}
            />
            <span className="text-xl tracking-wider">
              {data.site.siteMetadata.title}
            </span>
          </a>

          <ul className="md:flex text-sm">
            <li className="bg-brown hover:bg-gray hover:text-beige md:ml-6">
              <a href="/contact">Kontakt</a>
            </li>
          </ul>
        </header>
      )}
    />
  );
};

export default Header;
