import * as React from "react";
import { graphql } from "gatsby";

const ProjectPreview = ({ content }) => {
  //return <Layout>No Content </Layout>;
  console.log(content)
  return (
    <div>
      <div className="w-full flex items-center justify-start relative">
        <div className="w-auto md:w-2/12"></div>

        <div className="w-full md:w-8/12 bg-gray-100 py-24 relative flex">
          <a
            href="#"
           
            class ="blockrelative"
          >
            <h3 
              class ="h3Projects">
              {content.title}
            </h3>
            {/* <p className="px-2 text-lg md:text-xl text-gray">
              {content.title}
            </p> */}
          </a>
          <img
            src={content.image}
            className="absolute top-0 left-0 block w-full h-full object-cover"
          />
        </div>

        <div className="w-1/12 absolute top-0 right-0 h-full bg-gray-800"></div>
      </div>

      <div className="w-full h-12 border-black bg-brown flex justify-end">
        <div className="w-64 bg-gray-800"></div>
      </div>
    </div>
  );
};
export default ProjectPreview;
