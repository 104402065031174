import * as React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <main className="bg-brown font-sans h-screen">
      <Header />
      <main className="bg-brown font-sans h-auto">{children}</main>
      <Footer />
    </main>
  );
};
export default Layout;
